/* ==========================================================================
Fonts
========================================================================== */
@font-face {
  font-family: Mikado;
  src: url("./assets/fonts/mikadoregular-webfont.woff2") format("woff2"), url("./assets/fonts/mikadoregular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Mikado;
  src: url("./assets/fonts/mikadomedium-webfont.woff2") format("woff2"), url("./assets/fonts/mikadomedium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Mikado;
  src: url("./assets/fonts/mikadobold-webfont.woff2") format("woff2"), url("./assets/fonts/mikadobold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'MikadoBlack';
  src: local('MikadoBlack'), url("./assets/fonts/MikadoBlack.otf") format("truetype");
  font-weight: 700;
  font-style: normal
}

/* ==========================================================================
Default React stuff - TBD?
========================================================================== */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: #fafafa url("./assets/images/Backgroundimage.png");
  background-position-x: 50%;
  background-position-y: 0;
  background-size: 1441px 810px;
  font-family: Mikado, sans-serif;
  color: #333;
}

.round {
  position: relative;

  label {
    margin: 20px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;

    &:after {
      border: 5px solid #000;
      border-top: none;
      border-right: none;
      content: "";
      height: 10px;
      left: 5px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-55deg);
      width: 18px;
    }
  }

  input[type="checkbox"], .round input[type="radio"] {
    visibility: hidden;
  }

  input[type="checkbox"]:checked+label:after, .round input[type="radio"]:checked+label:after {
    opacity: 1;
  }

}

/* ==========================================================================
Support Tool
========================================================================== */

.support-tool-header {
  width: 100%;
  height: auto;
  margin: 25px auto auto;
  padding: 10px;

  background-color: rgba(255,255,255,0.6);
  z-index: 1;
  border-left: solid 8px;

  background-image: url("./assets/images/tm_logo.png");
  background-size: 240px;
  background-repeat: no-repeat;
  background-position: right;
}

.support-tool-header-title {
  font-weight: bold;
  font-size: 26px;
  color: #404040;
  display: inline-block;
}

.support-tool-box-title {
  width: 96%;
  height: 40px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-top: 5px;

  font-size: 20px;
  font-weight: bold;

  background-color: rgba(255,255,255,0.3);
  color: #404040;
  border-style: solid;
  border-radius: 30px;
  border-width: 0;
  border-left: solid 6px #404040;
  border-right: solid 6px #404040;
  border-top: solid 1px #404040;
  border-bottom: solid 1px #404040;
}

.support-tool-box-title:hover {
  cursor: pointer;
  background-color: rgba(255,255,255,0.6);
}

.support-tool-box {
  width: 94%;
  max-width: 1600px;
  height: auto;
  margin: 20px auto auto;
  display: block;
  position: relative;
  color: #404040;

  padding: 20px 10px 25px;

  background-color: rgba(255,255,255,0.2);
  border-style: solid;
  border-width: 0;
  border-color: #cdd7e0;
  border-radius: 10px;
  z-index: 1;
}

.support-tool-box-filter-button {
  border-radius: 10px;
  border-width: 0;
  cursor: pointer;
  margin: 5px;
}

.support-tool-box-filter-button:hover {
  background-color: #fce6ad;
}
.support-tool-box-filter-button-on {
  border-radius: 10px;
  border-width: 0;
  cursor: pointer;
  background-color: #fce6ad;
  margin: 5px;
}

.support-tool-box-help-button {
  border-top: 1px solid #404040;
  border-bottom: 1px solid #404040;
  border-left: 4px solid #404040;
  border-right: 4px solid #404040;
  border-radius: 20px;

  padding: 10px 15px;
  cursor: pointer;
}

.support-tool-box-help-button:hover {
  background-color: #fce6ad;
}



/* ==========================================================================
Content boxes
========================================================================== */

.new-content-header {
  width: 100%;
  height: auto;
  margin: 25px auto auto;
  padding: 10px;

  background-color: rgba(255,255,255,0.6);
  z-index: 1;
  border-left: solid 8px;

  background-image: url("./assets/images/tm_logo.png");
  background-size: 240px;
  background-repeat: no-repeat;
  background-position: right;
}

.new-content-header-title {
  font-weight: bold;
  font-size: 24px;
  display: inline-block;

}

.new-content-header-description {
  font-size: 14px;
}

.new-content-box-full {
  width: 100%;
  height: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 25px;

}

.new-content-box-centered {
  width: 90%;
  max-width: 1600px;
  height: auto;
  margin: 25px auto auto;
  display: block;
  position: relative;

  padding-bottom: 25px;

  background: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-radius: 10px;
  z-index: 1;
}

.new-content-box-title {
  width: 96%;
  height: 40px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-top: 5px;

  font-size: 20px;
  font-weight: bold;

  background-color: rgba(255,255,255,0.3);
  color: #404040;
  border-style: solid;
  border-radius: 30px;
  border-width: 0;
  border-left: solid 6px #404040;
  border-right: solid 6px #404040;
  border-top: solid 1px #404040;
  border-bottom: solid 1px #404040;
}

.new-content-box-title-button {
  float: left;
  font-weight: normal;
}

.new-content-box-body {
  width: 94%;
  max-width: 1600px;
  height: auto;
  margin: 20px auto auto;
  display: block;
  position: relative;
  color: #404040;

  padding: 20px 10px 25px;

  background-color: rgba(255,255,255,0.2);
  border-style: solid;
  border-width: 0;
  border-color: #cdd7e0;
  border-radius: 10px;
  z-index: 1;
}

.new-content-box-medium-filter-buttons {
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
}

/* ==========================================================================
TBDContent boxes
========================================================================== */

.content-header {
  width: 90%;
  max-width: 1600px;
  height: auto;
  margin: 25px auto auto;
  padding: 10px;

  background-color: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-radius: 5px;
  z-index: 1;

}

.content-header-title {
  font-weight: bold;
  font-size: 24px;
  display: inline-block;
}

.content-header-description {
  font-size: 14px;
}

.content-box-full {
  width: 100%;
  height: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 25px;

}

.content-box-centered {
  width: 90%;
  max-width: 1600px;
  height: auto;
  margin: 25px auto auto;
  display: block;
  position: relative;

  padding-bottom: 25px;

  background: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-radius: 10px;
  z-index: 1;
}

.flex-content-box-centered {
  width: 90%;
  max-width: 1600px;
  height: auto;
  margin: 25px auto auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  position: relative;

  padding-bottom: 25px;

  background: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-radius: 10px;
  z-index: 1;
}

.content-box-title {
  width: 100%;
  padding: 5px 10px 5px 10px;

  font-size: 16px;
  font-weight: bold;
  background-color: #D4D4D4 ;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.content-box-title-button {
  float: left;
  font-weight: normal;
}

.content-box-body {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.content-box-medium-filter-buttons {
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
}

.draggable-item {
  padding: 16px;
  margin-bottom: 12px;
  border-radius: 1.5625rem;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.5);
  background-color: white;
  display: flex;
  align-items: center;
}

.draggable-item-name {
  flex: 1;
  padding-right: 1rem;
}

/* ==========================================================================
Navbar
========================================================================== */

.navbar-link {
  color: #a1a3a6;
  padding-top: 8px;
}

.navbar-link:hover {
  text-decoration: none;
  color: #d0d2d3;
}

.navbar-dropdown-link {
  color: #333333;
  padding-left: 4px;
}

.navbar-dropdown-link:hover {
  text-decoration: none;
  font-weight: bold;
  color: #333333;
}

/* ==========================================================================
CSV Input
========================================================================== */

.react-csv-input {
  font-size: 14px;

  margin: 20px;
}

.react-csv-input-label {
  margin: 16px;
  font-weight: bold;
}

/* ==========================================================================
Modal
========================================================================== */

.modal-content-box {
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  background-color: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-radius: 5px;
}

.modal-content-title {
  width: 100%;
  padding: 10px;

  font-size: 16px;
  font-weight: bold;
}

.modal-content-body {
  width: 100%;
  padding: 10px;
}

/* ==========================================================================
Details Page
========================================================================== */

.details-row {
  margin-bottom: 0.5em;

  .form-check {
    margin-left: 1.25em;
  }
}

.details-title {
  width: 94%;
  max-width: 1600px;
  height: auto;
  margin: 25px auto auto;
  display: block;
  position: relative;

  font-weight: bold;
  font-size: 20px;
}

.details-box-title {
  width: 94%;
  max-width: 1600px;
  padding: 5px 10px 5px 10px;
  margin: 10px auto auto;

  font-size: 16px;
  font-weight: bold;
  background-color: #D4D4D4 ;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.details-box {
  width: 94%;
  max-width: 1600px;
  height: auto;
  margin: auto auto;
  padding: 10px;
  display: block;
  position: relative;

  padding-bottom: 25px;

  background: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 0;
}

.details-button-box {
  width: 94%;
  max-width: 1600px;
  height: auto;
  margin: 10px auto auto;
  padding: 10px;
  position: relative;

  padding-bottom: 25px;

  background: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border-color: #cdd7e0;
  border-radius: 10px;
  z-index: 1;
}


/* ==========================================================================
Spinner/Loader
========================================================================== */

/*.loader {
  z-index: 1000;
  width: 200px;
  height: 185px;
  margin: 15% auto auto 38%;
  background-color: #4e555b;
  background: url("./assets/images/loader_bg.png") no-repeat;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 20px;
  border-style: solid;
  border-width: 0;
}*/

.loader {
  z-index: 1000;
  width: 200px;
  height: 185px;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.loader .loader-bg {
  width: 50%;
  height: 50%;
  background: url("./assets/images/loader_bg.png") no-repeat;
  background-size: cover;
  opacity: 0.8;
  animation: mirror 1.5s linear infinite;
}

@keyframes mirror {
  from {transform:rotateY(0deg);}
  to {transform:rotateY(360deg);}
}

.details-section {
  cursor: pointer;
}

.details-section:hover{
  background-color: rgba(0,0,0,0.1);
}

/* ==========================================================================
TIBO
========================================================================== */

.tibo {
  margin-left: auto;
  margin-right: 0;
  width: 347px;
  height: 497px;
  background: url("./assets/images/tibo.png") no-repeat center top;
  background-size: contain;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.development {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 1000;
  background-image: url("./assets/images/development_ribbon.png");
  background-position: center center;
  background-repeat: no-repeat;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc
}

/* ==========================================================================
Authentication
========================================================================== */

.authentication-wrapper {
  margin: 50px auto auto 5%;
  vertical-align: middle;
  align-content: center;
  float: left;
}

/* ==========================================================================
Datepicker
========================================================================== */

.datePicker {
  width: 100%;
}

/* ==========================================================================
Dashboard
========================================================================== */

.dashboard-content {
  width: 90%;
  max-width: 1600px;
  height: auto;
  margin: 25px auto auto;
  display: block;
  position: relative;
  padding-bottom: 25px;

}

.dashboard-card {
  width: 48%;
  height: auto;
  display: block;
  float: left;
  padding-bottom: 10px;
  margin: 1% 0 20px;
}

.card-title {
  width: 100%;
  height: auto;
  padding: 5px 5px 5px 20px;
  border: solid 1px #cdd7e0;
  background-color: #f5f5f5;
  font-size: 24px;
  border-radius: 5px;
}

.card-content {
  width: 100%;
  height: auto;
  margin-top: 15px;
  border: solid 1px #cdd7e0;
  border-radius: 5px;
  background-color: #f5f5f5;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 16px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;
}

.hero-unit h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
}

.error {
  color: white;
  background-color: red;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (   min--moz-device-pixel-ratio: 2), only screen and (     -o-min-device-pixel-ratio: 2/1), only screen and (        min-device-pixel-ratio: 2), only screen and (                min-resolution: 192dpi), only screen and (                min-resolution: 2dppx) {
  .hipster {
    background: url("./assets/images/tibo2x.png") no-repeat center top;
    background-size: contain;
  }
}

.hand {
  cursor: pointer;
}

.pagination li a {
  cursor: pointer;
}

#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

.alert .popover pre {
  font-size: 10px;
}

.voffset {
  margin-top: 2px;
}

.voffset1 {
  margin-top: 5px;
}

.voffset2 {
  margin-top: 10px;
}

.voffset3 {
  margin-top: 15px;
}

.voffset4 {
  margin-top: 30px;
}

.voffset5 {
  margin-top: 40px;
}

.voffset6 {
  margin-top: 60px;
}

.voffset7 {
  margin-top: 80px;
}

.voffset8 {
  margin-top: 100px;
}

.voffset9 {
  margin-top: 150px;
}

/* start Password strength bar style */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point:last {
  margin: 0 !important;
}

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}

/* end Password strength bar style */
.tags .tag-item {
  margin: 2px;
  padding: 0 5px;
  display: inline-block;
  float: left;
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 26px;
  line-height: 25px;
  border: 1px solid #acacac;
  border-radius: 3px;
  background: -webkit-linear-gradient(top, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
  background: linear-gradient(to bottom, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
}

.tags .tag-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tags {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  padding: 1px;
  overflow: hidden;
  word-wrap: break-word;
  cursor: text;
  background-color: #fff;
  border: 1px solid darkgray;
  box-shadow: 1px 1px 1px 0 lightgray inset;
  height: 100%;
}

/* form components */
input[type=email], input[type=password], input[type=text], input[type=number] {
  width: 100%;
  padding: 8px 22px 9px;
  border-radius: 5px;
  border: 1px solid #dedede;
  outline: 0;
  font-size: 1rem;
  color: inherit;
  height: calc(1.5em + .75rem + 2px);
}

input[type=email].w-70, input[type=password].w-70, input[type=text].w-70, input[type=number].w-70 {
  width: 70%;
}

input[type=email].w-60, input[type=password].w-60, input[type=text].w-60, input[type=number].w-60 {
  width: 60%;
}
input[type=email].w-50, input[type=password].w-50, input[type=text].w-50, input[type=number].w-50 {
  width: 50%;
}
input[type=email].w-40, input[type=password].w-40, input[type=text].w-40, input[type=number].w-40 {
  width: 40%;
}


input[type=textWithButton]{
  width: 80%;
  border-radius: 35px;
  padding: 8px 22px 9px;
    height: calc(1.5em + .75rem + 2px);
  border: 1px solid #dedede;
  outline: 0;
    font-size: 1rem;
  color: inherit;
  float: left;
}

input[type=numberRound]{
  width: 20%;
  border-radius: 35px;
  padding: 8px 22px 9px;
  height: 49px;
  border: 1px solid #dedede;
  outline: 0;
  font-size: 18px;
  color: inherit;
  float: left;
}

label {
  display: inline-block;
  padding-left: 5px;
  font-size: 18px;
  font-weight: 500;
  color: inherit
}

.input-btn {
  width: 100%;
  position: relative;
  float: left;
}

.modal{
  overflow-y: auto
}

.react-csv-input {
}
/*================
    BUTTONS
  ================*/

.btn-ci {
  display: block;
  width: auto;
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  font-size: 14px;
  background-color: #ec8320;
  /*background-color: #fdbe01;*/
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  float: right;
}

.btn-ci:hover {
  background-color: #fdbe01;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;

  font-size: 12px;
  background-color: #ec8320;
  /*background-color: #fdbe01;*/
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  float: left;
}

.form-btn-ci:hover {
  background-color: #fdbe01;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci-blue {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;

  font-size: 12px;
  background-color: #337ab7;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  float: left;
}

.form-btn-ci-blue:hover {
  background-color: #398cd2;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci-green {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;

  font-size: 12px;
  background-color: #259700;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  float: left;
}

.form-btn-ci-green:hover {
  background-color: #2CB200;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci-light {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;

  font-size: 12px;
  background-color: #9dacb9;
  text-decoration: none;
  color: #000000;
  cursor: pointer;
  float: left;
}

.form-btn-ci-light:hover {
  background-color: #b0c2d2;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci-red {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;

  font-size: 12px;
  background-color: #d9534f;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  float: left;
}

.form-btn-ci-red:hover {
  background-color: #f05b56;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci-light-blue {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;

  font-size: 12px;
  background-color: #5cc0de;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  float: left;
}

.form-btn-ci-light-blue:hover {
  background-color: #40caf3;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
}

.form-btn-ci-off {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  border: 0;
  font-size: 12px;
  background-color: #D4D4D4;
  /*background-color: #fdbe01;*/
  text-decoration: none;
  color: #fff;
  cursor: not-allowed;
  float: left;
}

.btn-ci span {
  display: block;
  padding: 11px 28px 10px;
  border-radius: 27px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
  background-color: #e50045;
  font-weight: 500
}

.toggle-button-on {
  width: auto;
  margin: auto;
  padding: 10px 30px;
  outline: 0;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  font-size: 12px;
  background-color: #ec8320;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  border: 1px inset;
}

.toggle-button-on:hover {
  background-color: #fdbe01;
}

.toggle-button-off {
  width: auto;
  margin: auto;
  padding: 10px 30px;
  outline: 0;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .5);
  font-size: 12px;
  background-color: #D4D4D4;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  border: 1px outset;
}

.toggle-button-off:hover {
  background-color: #fdbe01;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.details-badge-container .badge {
  font-size: 1.5em;
}

.badge-selected, .bg-selected {
  background-color: #007bff;
}

.badge-light-blue, .bg-light-blue {
  background-color: #5cc0de;
}

.badge-cyan, .bg-cyan {
  background-color: #17a2b8;
}

.badge-yellow, .bg-yellow {
  color: #676767;
  background-color: yellow;
}

.badge-darkred, .bg-darkred {
  background-color: #f05b56;
}

.badge-middle-red, .bg-middle-red {
  color: #676767;
  background-color: #ed969e;
}

.badge-light-red, .bg-light-red {
  color: #676767;
  background-color: #f5c6cb;
}


.badge-box-red, .bg-box-red {
  background-color: #e62319;
}
.badge-box-blue, .bg-box-blue {
  background-color: #00abed;
}
.badge-box-grey, .bg-box-grey {
  color: black;
  background-color: #abb8c3;
}
.badge-box-black, .bg-box-black {
  background-color: black;
}
.badge-box-green, .bg-box-green {
   background-color: #1eaf8c;
 }
.badge-box-yellow, .bg-box-yellow {
    color: #676767;
    background-color: #ffde00;
  }
.badge-box-purple, .bg-box-purple {
     background-color: #a51482;
   }